<ng-container>
  <div class="row">
    <!-- transactionalAside aka 'Buy and sell' section-->
    <div class="col-12 col-md-2">
      <h2 class="header-text">{{ content.transactionalAside.header }}</h2>
      <div class="links-container">
        <a *ngFor="let link of content.transactionalAside.links"
           href="{{ link.url }}"
          c11n-link
          variant="secondary-independent"
          size="medium"
          fontWeight="normal"
          >{{ link.label }}</a
        >
      </div>
    </div>

    <!-- Marketing B-spot container -->
    <div class="col-12 col-md-4">
      <ng-container *gatekeeper="'interact'">
        <div id="target-bspot-offer" class="target-bspot-offer">
        </div>
      </ng-container>

      <h2 class="header-text">{{ content.bSpot.header }}</h2>
      <div class="links-container">
        <p class="bspot-text">{{ content.bSpot.bodyText }}</p>
        <a *ngFor="let link of content.bSpot.links"
           href="{{ link.url }}"
           c11n-link
           variant="secondary-independent"
           size="medium"
           fontWeight="normal">
          {{ link.label }}
        </a>
      </div>
    </div>

    <!-- clericalAside aka 'Frequently Requested Links' section-->
    <div class="col-12 col-md-6">
      <h2 class="header-text">{{ content.clericalAside.header }}</h2>
      <div class="row">
        <!-- clericalAside aka 'Frequently Requested Links' section, Column 1-->
        <div class="col-xs-12 col-sm-6 links-container">
          <!-- WatchList -->
          <a
            href="{{content.clericalAside.myWatchListUrl }}"
            c11n-link
            variant="secondary-independent"
            size="medium"
            fontWeight="normal"
            [attr.data-testid]="'watch-list_link'">{{ content.clericalAside.myWatchListLabel }}</a
          >
          <!-- Update Beneficiaries -->
          <a
            href="{{ content.clericalAside.updateBeneficiariesUrl }}"
            c11n-link
            variant="secondary-independent"
            size="medium"
            fontWeight="normal"
            >{{ content.clericalAside.updateBeneficiariesLabel }}</a
          >
          <!-- addABank -->
          <a
            href="{{ content.clericalAside.addABankUrl }}"
            c11n-link
            variant="secondary-independent"
            size="medium"
            fontWeight="normal"
            >{{ content.clericalAside.addABankLabel }}</a
          >
          <!-- changeMyPassword -->
          <a
            href="{{ content.clericalAside.changeMyPasswordUrl }}"
            c11n-link
            variant="secondary-independent"
            size="medium"
            fontWeight="normal"
            >{{ content.clericalAside.changeMyPasswordLabel }}</a
          >
          <!-- viewMyStatements -->
          <a
            href="{{ content.clericalAside.viewMyStatementsUrl }}"
            c11n-link
            variant="secondary-independent"
            size="medium"
            fontWeight="normal"
            >{{ content.clericalAside.viewMyStatementsLabel }}</a
          >
        </div>

        <!-- clericalAside aka 'Frequently Requested Links' section, Column 2-->
        <div class="col-xs-12 col-sm-6 links-container">
          <!-- setUpAutomaticInvesting -->
          <a
            href="{{ content.clericalAside.setUpAutomaticInvestingUrl }}"
            c11n-link
            variant="secondary-independent"
            size="medium"
            fontWeight="normal"
            >{{ content.clericalAside.setUpAutomaticInvestingLabel }}</a
          >
          <!-- getWireTransferInstructions -->
          <a
            href="{{ content.clericalAside.getWireTransferInstructionsUrl }}"
            c11n-link
            variant="secondary-independent"
            size="medium"
            fontWeight="normal"
            >{{ content.clericalAside.getWireTransferInstructionsLabel }}</a
          >
          <ng-container>
            <!-- planCommunications -->
            <a *ngIf="isCrossoverClient"
               href="{{ content.clericalAside.planCommunicationsUrl }}"
              c11n-link
              variant="secondary-independent"
              size="medium"
              fontWeight="normal"
              >{{ content.clericalAside.planCommunicationsLabel }}</a
            >
            <!-- retirementPlanStatements -->
            <a *ngIf="isCrossoverClient"
               href="{{ content.clericalAside.retirmentPlanStatementsUrl }}"
              c11n-link
              variant="secondary-independent"
              size="medium"
              fontWeight="normal"
              >{{ content.clericalAside.retirmentPlanStatementsLabel }}</a
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};



export type Account = {
  __typename?: 'Account';
  accountFlags?: Maybe<AccountFlags>;
  accountNumber529?: Maybe<Scalars['String']>;
  accountType: Scalars['String'];
  asOfDate?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  brokerageAccountNumber?: Maybe<Scalars['String']>;
  externalAccountId?: Maybe<Scalars['String']>;
  grouping: Scalars['String'];
  id: Scalars['String'];
  isBrokerage: Scalars['Boolean'];
  isCashManagement: Scalars['Boolean'];
  isEnrolledToFullyPaidLending?: Maybe<Scalars['Boolean']>;
  isNBAInvestCash: Scalars['Boolean'];
  isParticipant: Scalars['Boolean'];
  isRetirement: Scalars['Boolean'];
  isUpgradeEligible?: Maybe<Scalars['Boolean']>;
  lastRefreshedDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  personalTrustAccountInformation?: Maybe<PersonalTrustAccountInformation>;
  positions?: Maybe<Array<Holding>>;
  productType: Scalars['String'];
  registration?: Maybe<AccountRegistration>;
  retirementPlanType?: Maybe<Scalars['String']>;
};

export type AccountErrors = {
  __typename?: 'AccountErrors';
  hasInstitutionalError?: Maybe<Scalars['Boolean']>;
};

export type AccountFlags = {
  __typename?: 'AccountFlags';
  accountId: Scalars['String'];
  /** Registered role features of this account for current poid. */
  clientRegisteredRoleFeatures: RegisteredRoleFeatures;
  is529Bene: Scalars['Boolean'];
  isPrimaryOwner: Scalars['Boolean'];
  mergeExpandPending: Scalars['Boolean'];
  transferOfAssetsPending: Scalars['Boolean'];
};

export type AccountRegistration = {
  __typename?: 'AccountRegistration';
  subType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountRegistrationDetails = {
  __typename?: 'AccountRegistrationDetails';
  accountId?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Scalars['String']>>;
  phoneNumbers?: Maybe<Array<Phone>>;
  registrationAddress?: Maybe<Address>;
  taxAddress?: Maybe<Address>;
};

export type AccountsInfo = {
  __typename?: 'AccountsInfo';
  accountErrors?: Maybe<AccountErrors>;
  accounts: Array<Account>;
  privateEquityInfo?: Maybe<PrivateEquityInfo>;
  totals?: Maybe<Totals>;
};

export type Activity = {
  __typename?: 'Activity';
  accountId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  brokerageAccountNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settlementDate?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  accountMailingAddress?: Maybe<Scalars['Boolean']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  addressLine4?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  foreign?: Maybe<Scalars['Boolean']>;
  isForeign?: Maybe<Scalars['Boolean']>;
  stateCode?: Maybe<Scalars['String']>;
  taxAddress?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Information on the Vanguard account associated with a client's private equity account. */
export type AssociatedAccount = {
  __typename?: 'AssociatedAccount';
  /** Associated Vanguard account's ID */
  accountId?: Maybe<Scalars['String']>;
  /** Associated Vanguard account's product type */
  productType?: Maybe<AssociatedAccountProductType>;
  /** Account number for vendor */
  vendorAccountNumber?: Maybe<Scalars['String']>;
};

export enum AssociatedAccountProductType {
  Brokerage = 'BROKERAGE',
  Sungard = 'SUNGARD'
}

export type AutoTransactionStatus = {
  __typename?: 'AutoTransactionStatus';
  automaticInvestmentStatus?: Maybe<BankingAndMoneyMovementStatus>;
  automaticWithdrawalStatus?: Maybe<BankingAndMoneyMovementStatus>;
};

export type AutomaticTransactions = {
  __typename?: 'AutomaticTransactions';
  accountId?: Maybe<Scalars['String']>;
  autoTransactionStatus?: Maybe<AutoTransactionStatus>;
  plans?: Maybe<Array<Plans>>;
};

export type BankAndWireTransferStatus = {
  __typename?: 'BankAndWireTransferStatus';
  transferStatus?: Maybe<BankingAndMoneyMovementStatus>;
  typeOfTransfer?: Maybe<Scalars['String']>;
};

export type BankingAndMoneyMovement = {
  __typename?: 'BankingAndMoneyMovement';
  autoTransactionStatus?: Maybe<AutoTransactionStatus>;
  capitalGains?: Maybe<Scalars['String']>;
  checkWritingStatus?: Maybe<BankingAndMoneyMovementStatus>;
  costBasisMethod?: Maybe<Scalars['String']>;
  directDepositStatus?: Maybe<BankingAndMoneyMovementStatus>;
  dividends?: Maybe<Scalars['String']>;
  wireAndBankTransferStatus?: Maybe<Array<BankAndWireTransferStatus>>;
};

export enum BankingAndMoneyMovementStatus {
  Established = 'ESTABLISHED',
  Na = 'NA',
  NotEstablished = 'NOT_ESTABLISHED'
}

export type BrokeragePosition = {
  __typename?: 'BrokeragePosition';
  accountId: Scalars['String'];
  isCashManagementAccountFlag: Scalars['Boolean'];
};

export type CashDepositSettlementFundApy = {
  __typename?: 'CashDepositSettlementFundApy';
  pbkApy?: Maybe<Scalars['Float']>;
};

export type CashExperience = {
  __typename?: 'CashExperience';
  accountId?: Maybe<Scalars['String']>;
  availableBalance?: Maybe<Scalars['Float']>;
  expectedDatetime?: Maybe<Scalars['String']>;
  isPending?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  settlementVehicleName?: Maybe<CashExperienceVehicleNameEnum>;
};

export type CashExperienceApy = {
  __typename?: 'CashExperienceApy';
  accountApy?: Maybe<Scalars['Float']>;
  accountApyName?: Maybe<Scalars['String']>;
};

export enum CashExperienceVehicleNameEnum {
  Fed = 'FED',
  Pbk = 'PBK'
}

export type CheckWriting = {
  __typename?: 'CheckWriting';
  checkWritingStatus?: Maybe<BankingAndMoneyMovementStatus>;
};

export type Client = {
  __typename?: 'Client';
  crewFlag: Scalars['Boolean'];
  dateOfBirth: Scalars['String'];
  inheritedRequiresRMD: Scalars['Boolean'];
  institutional: Scalars['Boolean'];
  isAssetManagement: Scalars['Boolean'];
  isCrossOverClient: Scalars['Boolean'];
  isDigitalAdvice: Scalars['Boolean'];
  isIIGClient: Scalars['Boolean'];
  isRegisteredWithYodlee: Scalars['Boolean'];
  lastLoginTimestamp?: Maybe<Scalars['String']>;
  lastLoginTimestampWithTimeZone?: Maybe<Scalars['DateTime']>;
  managedAccountStatus?: Maybe<ManagedAccountStatusEnum>;
  preferredName: Scalars['String'];
  retirementRequiresRMD: Scalars['Boolean'];
};

export type ClientAlerts = {
  __typename?: 'ClientAlerts';
  hasProvisionalVBARestriction: Scalars['Boolean'];
  recentlyAddedBank: Scalars['Boolean'];
  returnByPostOffice: Scalars['Boolean'];
};

export enum ConstellationIconSize {
  Medium = 'medium',
  Small = 'small'
}

/** General contact information and representative(s) associated with a client. */
export type ContactUs = {
  __typename?: 'ContactUs';
  /** The URL for "Advisor" appointments. */
  advisorAppointmentUrl: Scalars['String'];
  /** Indicates whether the client is a Flagship client. */
  flagshipClient: Scalars['Boolean'];
  /** The URL for "Relationship Manager" appointments. */
  relationshipManagerAppointmentUrl: Scalars['String'];
  /** The representatives (Advisor or Relationship Manager) associated with the client. */
  representativeContacts: Array<RepresentativeContact>;
  /** Indicates whether the client is an RMA client. */
  rmaClient: Scalars['Boolean'];
  /** The "global" support phone number. */
  supportPhoneNumber: Scalars['String'];
  /** The "global" support schedule. */
  supportSchedule: Array<Schedule>;
};

export type Content = {
  __typename?: 'Content';
  actionButtonLabel?: Maybe<Scalars['String']>;
  actionUrl?: Maybe<Scalars['String']>;
  bodyContent?: Maybe<Scalars['String']>;
  closeButtonLabel?: Maybe<Scalars['String']>;
  constellationIconName?: Maybe<Scalars['String']>;
  constellationIconSize?: Maybe<ConstellationIconSize>;
  contentId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  imageAltText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  optionalInternalActionUrl?: Maybe<Scalars['String']>;
};

export type ContingencyFlags = {
  __typename?: 'ContingencyFlags';
  isContingency: Scalars['Boolean'];
  isEtfsAtp: Scalars['Boolean'];
  isIpl: Scalars['Boolean'];
  isMutualFundsAtp: Scalars['Boolean'];
};

export type CostBasis = {
  __typename?: 'CostBasis';
  accountId?: Maybe<Scalars['String']>;
  costBasisMethod?: Maybe<Scalars['String']>;
  costPerShare?: Maybe<Scalars['Float']>;
  hasUnallocated?: Maybe<Scalars['Boolean']>;
  percentGainLoss?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalGainLoss?: Maybe<Scalars['Float']>;
};

export type CostBasisByAccount = {
  __typename?: 'CostBasisByAccount';
  costBasisMethod?: Maybe<Scalars['String']>;
  costPerShare?: Maybe<Scalars['Float']>;
  hasUnallocated?: Maybe<Scalars['Boolean']>;
  percentGainLoss?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalGainLoss?: Maybe<Scalars['Float']>;
};

export type CostBasisTotals = {
  __typename?: 'CostBasisTotals';
  accountId?: Maybe<Scalars['String']>;
  costBasis?: Maybe<Array<CostBasisByAccount>>;
  totalGainLoss?: Maybe<Scalars['Float']>;
};

export enum CusipPrincipalIncomeCodeType {
  Cash = 'CASH',
  Reinvest = 'REINVEST'
}

/** Contains information to determine the order in which a client wants to view their accounts. */
export type CustomOrderRule = {
  __typename?: 'CustomOrderRule';
  accountId: Scalars['String'];
  /** Indicates the order in which an account appears. */
  displayOrder: Scalars['Float'];
};

/** Holds custom view information. */
export type CustomViewDetails = {
  __typename?: 'CustomViewDetails';
  clientPoId: Scalars['String'];
  /** List of accounts with their order a client wants to view. */
  customOrderRules?: Maybe<Array<CustomOrderRule>>;
  /** If true then the client has a custom view, custom order, or has selected to hide zero balance accounts/positions. */
  customView: Scalars['Boolean'];
  /** List of accounts or positions the client has elected to hide. */
  customViewRules?: Maybe<Array<CustomViewRule>>;
  /** If true then the client has selected to hide zero balance accounts and positions. */
  hideZeroBalance: Scalars['Boolean'];
};

/** Contains information to determine which accounts or positions the client has elected to hide. */
export type CustomViewRule = {
  __typename?: 'CustomViewRule';
  accountId: Scalars['String'];
  /** List of positionIds to be excluded. */
  excludedPositions?: Maybe<Array<Scalars['String']>>;
};

export type CutoffTimes = {
  __typename?: 'CutoffTimes';
  exchangeCutOffTime?: Maybe<Scalars['String']>;
  purchaseCutOffTime?: Maybe<Scalars['String']>;
  redemptionCutOffTime?: Maybe<Scalars['String']>;
};


export type DirectDepositStatus = {
  __typename?: 'DirectDepositStatus';
  directDepositStatus?: Maybe<BankingAndMoneyMovementStatus>;
};

export type Distribution = {
  __typename?: 'Distribution';
  accruedDividend?: Maybe<Scalars['Float']>;
  accruedDividendDate?: Maybe<Scalars['String']>;
  distributionAmount?: Maybe<Scalars['Float']>;
  dividend?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  longTermGain?: Maybe<Scalars['Float']>;
  otherIncome?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  shortTermGain?: Maybe<Scalars['Float']>;
};

export type DivCapGains = {
  __typename?: 'DivCapGains';
  accountId: Scalars['String'];
  nonTaxableDistributions?: Maybe<Array<Distribution>>;
  payouts?: Maybe<Array<Payout>>;
  taxableDistributions?: Maybe<Array<Distribution>>;
};

export type FixedIncomeDetails = {
  __typename?: 'FixedIncomeDetails';
  annualCoupon?: Maybe<Scalars['String']>;
  assetClass?: Maybe<Scalars['String']>;
  bondFactorRate?: Maybe<Scalars['Float']>;
  callableIndicator?: Maybe<Scalars['String']>;
  maturityDate?: Maybe<Scalars['String']>;
  moodysRating?: Maybe<Scalars['String']>;
  nextCallDate?: Maybe<Scalars['String']>;
  nextCallPrice?: Maybe<Scalars['Float']>;
  nextCouponDate?: Maybe<Scalars['String']>;
  nextPutDate?: Maybe<Scalars['String']>;
  nextPutPrice?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<Scalars['String']>;
  preRefundedDate?: Maybe<Scalars['String']>;
  putableIndicator?: Maybe<Scalars['String']>;
  standardAndPoorsRating?: Maybe<Scalars['String']>;
};

/** Information about a fund in a private equity account */
export type Fund = {
  __typename?: 'Fund';
  /** The amount of money an investor promises to a venture capital fund */
  capitalCommitmentAmount?: Maybe<Scalars['Float']>;
  capitalCommitmentAsOfDate?: Maybe<Scalars['String']>;
  /** The amount of money that shareholders have given a company in exchange for stock */
  capitalContributionAmount?: Maybe<Scalars['Float']>;
  capitalContributionAsOfDate?: Maybe<Scalars['String']>;
  /** Account owner takes distributions in the form of taxable income */
  distributionsAmount?: Maybe<Scalars['Float']>;
  distributionsAsOfDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Difference between Capital Contribution and Distributions */
  totalBalance?: Maybe<Scalars['Float']>;
  totalBalanceAsOfDate?: Maybe<Scalars['String']>;
};

export type HistoryOfHoldingRevenue = {
  __typename?: 'HistoryOfHoldingRevenue';
  collateralValue?: Maybe<Scalars['Float']>;
  dailyAccrual?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
  totalSharesOnLoan?: Maybe<Scalars['Float']>;
};

export type Holding = {
  __typename?: 'Holding';
  accountId?: Maybe<Scalars['String']>;
  accountTypeCode?: Maybe<Scalars['String']>;
  brokerageAccountNumber?: Maybe<Scalars['String']>;
  currentBalance?: Maybe<Scalars['Float']>;
  cusip?: Maybe<Scalars['String']>;
  externalPositionId?: Maybe<Scalars['String']>;
  frequentTradingPolicyFreeze?: Maybe<Scalars['Boolean']>;
  gainLoss?: Maybe<Scalars['Float']>;
  holdingLongName?: Maybe<Scalars['String']>;
  holdingShortName?: Maybe<Scalars['String']>;
  isValidTicker?: Maybe<Scalars['Boolean']>;
  loanDetails?: Maybe<HoldingLoanDetails>;
  longName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  personalTrustPositionInformation?: Maybe<PersonalTrustPositionInformation>;
  portId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  positionIndex?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  previousClosePrice?: Maybe<Scalars['Float']>;
  previousCloseValue?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceAsOfDate?: Maybe<Scalars['String']>;
  priceChangeAmount?: Maybe<Scalars['Float']>;
  priceChangePercent?: Maybe<Scalars['Float']>;
  purchaseAmount?: Maybe<Scalars['Float']>;
  purchaseValue?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  realTime?: Maybe<Scalars['Boolean']>;
  securityClassType?: Maybe<Scalars['String']>;
  securityKey?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  shortName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  vastAccountNumber?: Maybe<Scalars['String']>;
};

export type HoldingByAccount = {
  __typename?: 'HoldingByAccount';
  holdings: Array<Holding>;
  id: Scalars['String'];
};

export enum HoldingIdType {
  Cusip = 'cusip',
  PositionId = 'positionId',
  Ticker = 'ticker'
}

export type HoldingLoanDetails = {
  __typename?: 'HoldingLoanDetails';
  currentTotalSharesOnLoan?: Maybe<Scalars['Float']>;
  historyOfRevenues?: Maybe<Array<HistoryOfHoldingRevenue>>;
  mtdAccrual?: Maybe<Scalars['Float']>;
  mtdAccrualTimeStamp?: Maybe<Scalars['String']>;
  ytdAccrual?: Maybe<Scalars['Float']>;
  ytdAccrualUpdatedTimeStamp?: Maybe<Scalars['String']>;
};

export type HoldingOptions = {
  __typename?: 'HoldingOptions';
  accountId?: Maybe<Scalars['String']>;
  capitalGains?: Maybe<Scalars['String']>;
  costBasisMethod?: Maybe<Scalars['String']>;
  dividends?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
};

export type HoldingPricesAndReturns = {
  __typename?: 'HoldingPricesAndReturns';
  cusip?: Maybe<Scalars['String']>;
  fundId?: Maybe<Scalars['String']>;
  isVanguardFund?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  portId?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  returns?: Maybe<Returns>;
  securityClassType?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Interventions = {
  __typename?: 'Interventions';
  accountIds: Array<Scalars['String']>;
  content: InterventionsContent;
  placementId: Scalars['String'];
};

export type InterventionsContent = {
  __typename?: 'InterventionsContent';
  actionButtonLabel: Scalars['String'];
  actionUrl: Scalars['String'];
  body: Scalars['String'];
  closeButtonLabel: Scalars['String'];
  header: Scalars['String'];
  templateId: Scalars['String'];
};

export enum ManagedAccountStatusEnum {
  Accepted = 'ACCEPTED',
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  SecondaryOwner = 'SECONDARY_OWNER',
  Send = 'SEND',
  Terminated = 'TERMINATED'
}

export type NextBestAction = {
  __typename?: 'NextBestAction';
  accountIds: Array<Scalars['String']>;
  id: Scalars['String'];
};

export type NextBestActionV2 = {
  __typename?: 'NextBestActionV2';
  accountIds?: Maybe<Array<Scalars['String']>>;
  communicationChannel?: Maybe<Scalars['String']>;
  content?: Maybe<Content>;
  contentTemplateId?: Maybe<Scalars['String']>;
  nbaId?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['String']>;
};

export type NextBestActions = {
  __typename?: 'NextBestActions';
  nextBestActions: Array<NextBestAction>;
  poid: Scalars['String'];
};

export type NextBestActionsV2 = {
  __typename?: 'NextBestActionsV2';
  nextBestActions: Array<NextBestActionV2>;
};

export type NextIntervention = {
  __typename?: 'NextIntervention';
  id: Scalars['String'];
  interventions: Array<Interventions>;
};

export type NextInterventions = {
  __typename?: 'NextInterventions';
  nextBestActions: Array<NextIntervention>;
  poid: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  contractType?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  strikePrice?: Maybe<Scalars['String']>;
};

export type Payout = {
  __typename?: 'Payout';
  distributionRate?: Maybe<Scalars['Float']>;
  election?: Maybe<Scalars['String']>;
  exReinvestDate?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<Scalars['Float']>;
  paidDate?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  reinvestPrice?: Maybe<Scalars['Float']>;
  sharesTransacted?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type PercentageReturns = {
  __typename?: 'PercentageReturns';
  benchmarkPercentage?: Maybe<Scalars['Float']>;
  durationPercentage?: Maybe<Scalars['Float']>;
  durationType?: Maybe<PercentageReturnsDurationType>;
  marketPercentage?: Maybe<Scalars['Float']>;
};

export enum PercentageReturnsDurationType {
  FiveYear = 'FIVE_YEAR',
  Inception = 'INCEPTION',
  OneYear = 'ONE_YEAR',
  TenYear = 'TEN_YEAR',
  ThreeYear = 'THREE_YEAR',
  Ytd = 'YTD'
}

export type PersonalTrustAccountCashAmounts = {
  __typename?: 'PersonalTrustAccountCashAmounts';
  income?: Maybe<Scalars['Float']>;
  principal?: Maybe<Scalars['Float']>;
};

export type PersonalTrustAccountInformation = {
  __typename?: 'PersonalTrustAccountInformation';
  cashAmounts?: Maybe<PersonalTrustAccountCashAmounts>;
};

export type PersonalTrustPositionInformation = {
  __typename?: 'PersonalTrustPositionInformation';
  assetType?: Maybe<Scalars['String']>;
  cusipPrincipalIncomeType?: Maybe<CusipPrincipalIncomeCodeType>;
};

export type Phone = {
  __typename?: 'Phone';
  areaCode?: Maybe<Scalars['String']>;
  cityCode?: Maybe<Scalars['String']>;
  countryTelephoneCode?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  internationalCode?: Maybe<Scalars['String']>;
  mobileDeviceType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberDevice?: Maybe<Scalars['String']>;
  phoneNumberId?: Maybe<Scalars['String']>;
  phoneNumberType?: Maybe<Scalars['String']>;
};

export type Plans = {
  __typename?: 'Plans';
  amount?: Maybe<Scalars['Float']>;
  planType?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  scheduledTransactionDate?: Maybe<Scalars['String']>;
  transactionFrequencyCode?: Maybe<Scalars['String']>;
};

export type PrevBusinessDayPercentageReturns = {
  __typename?: 'PrevBusinessDayPercentageReturns';
  durationPercentage?: Maybe<Scalars['Float']>;
  durationType?: Maybe<PrevBusinessDayPercentageReturnsDurationType>;
};

export enum PrevBusinessDayPercentageReturnsDurationType {
  LastBusinessDayYtd = 'LAST_BUSINESS_DAY_YTD'
}

export type Price = {
  __typename?: 'Price';
  expenseRatio?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceAsOfDate?: Maybe<Scalars['String']>;
  priceChangeAmount?: Maybe<Scalars['Float']>;
  priceChangePercent?: Maybe<Scalars['Float']>;
  secYieldDate?: Maybe<Scalars['String']>;
  /** Deprecated, data included in text field included in secYieldNote fields */
  secYieldFootnote?: Maybe<Scalars['String']>;
  secYieldNote?: Maybe<Array<SecYieldNote>>;
  /** Deprecated, data included in code field included in secYieldNote fields */
  secYieldNoteCode?: Maybe<Scalars['String']>;
  /** Deprecated, data included in period field included in secYieldNote fields */
  secYieldPeriod?: Maybe<Scalars['String']>;
  secYieldValue?: Maybe<Scalars['String']>;
};

export type PricesAndReturns = {
  __typename?: 'PricesAndReturns';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  asOfDate: Scalars['String'];
  balance: Scalars['Float'];
  grouping?: Maybe<Scalars['String']>;
  holdings: Array<HoldingPricesAndReturns>;
  managed: Scalars['Boolean'];
  productType?: Maybe<Scalars['String']>;
};

/** A client's private equity account information */
export type PrivateEquity = {
  __typename?: 'PrivateEquity';
  /** Account name */
  accountName?: Maybe<Scalars['String']>;
  /** Information on the Vanguard account associated with this private equity account. */
  associatedAccount?: Maybe<AssociatedAccount>;
  /**
   * VBA Account
   * @deprecated Use associatedAccount property which supports non-brokerage associated accounts.
   */
  brokerageAccountId?: Maybe<Scalars['String']>;
  /** Collection of funds associated with account */
  funds?: Maybe<Array<Fund>>;
  /** Unique identifier of the investor */
  investorId?: Maybe<Scalars['String']>;
  /** Limited Partner ID */
  partnerId?: Maybe<Scalars['String']>;
  /** Sum of all capital account balances */
  totalBalance?: Maybe<Scalars['Float']>;
};

export type PrivateEquityInfo = {
  __typename?: 'PrivateEquityInfo';
  privateEquityAccounts: Array<PrivateEquity>;
};

export type Query = {
  __typename?: 'Query';
  accountRegistrationDetails: Array<AccountRegistrationDetails>;
  /** Returns account list containing balance information */
  accountsInfo: AccountsInfo;
  /** Shows auto transaction data and plans types status. */
  automaticTransactions?: Maybe<AutomaticTransactions>;
  /** Shows if account is eligible for Bank And Wire transfer */
  bankAndWireTransferStatus?: Maybe<Array<BankAndWireTransferStatus>>;
  /** Returns data for banking and money movement table */
  bankingAndMoneyMovement?: Maybe<BankingAndMoneyMovement>;
  /** Returns Brokerage Account Ids and Cash Management Account flag which is used for mapping to AAN response */
  brokeragePositions: Array<BrokeragePosition>;
  /** Returns apy balance for Cash Deposit Settlement Fund */
  cashDepositSettlementFundApy?: Maybe<CashDepositSettlementFundApy>;
  cashExperience: Array<CashExperience>;
  /** Returns apy balance for Cash Plus */
  cashExperienceApy?: Maybe<CashExperienceApy>;
  /** Returns the next best actions from the channel broker api */
  channelBroker: NextBestActions;
  /** Returns the next best interventions from the channel broker api */
  channelBrokerInterventions: NextInterventions;
  /** Returns the next best actions from the channel broker api */
  channelBrokerNextBestActions: NextBestActionsV2;
  /** Shows if the holding is enrolled in check writing. */
  checkWriting?: Maybe<CheckWriting>;
  client: Client;
  clientAlerts: ClientAlerts;
  /** Returns info on client representatives and means of contacting them. */
  contactUs?: Maybe<ContactUs>;
  contingencyFlags: ContingencyFlags;
  /** Returns cost basis summary for positions. */
  costBasis?: Maybe<Array<CostBasis>>;
  /** Returns cost basis summary information for a specific account */
  costBasisByAccount?: Maybe<Array<CostBasisByAccount>>;
  /** Returns cost basis summary for accounts */
  costBasisTotals?: Maybe<Array<CostBasisTotals>>;
  customViewDetails: CustomViewDetails;
  /** Shows if the holding is enrolled in direct deposit. */
  directDepositStatus?: Maybe<DirectDepositStatus>;
  /** Returns dividends and capital gains information */
  divCapGains: Array<DivCapGains>;
  /** Returns Holding Options */
  holdingOptions: HoldingOptions;
  pricesAndReturns: Array<PricesAndReturns>;
  securities: Array<Security>;
  /** Returns Settlement Funds for all accounts. */
  settlementFund: Array<SettlementFund>;
  transactionHistory: Array<TransactionHistory>;
  /** Count of unread secure email messages. */
  unreadSecureMessageCount: Scalars['Int'];
};


export type QueryAccountRegistrationDetailsArgs = {
  accountIds: Scalars['String'];
};


export type QueryAccountsInfoArgs = {
  excludeCustomView?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
};


export type QueryAutomaticTransactionsArgs = {
  accountId: Scalars['String'];
  holdingId?: Maybe<Scalars['String']>;
  holdingIdType?: Maybe<HoldingIdType>;
  positionId?: Maybe<Scalars['String']>;
};


export type QueryBankAndWireTransferStatusArgs = {
  accountId: Scalars['String'];
};


export type QueryBankingAndMoneyMovementArgs = {
  accountId: Scalars['String'];
  positionId: Scalars['String'];
};


export type QueryCashExperienceApyArgs = {
  svName?: Maybe<Scalars['String']>;
};


export type QueryChannelBrokerInterventionsArgs = {
  placementId: Scalars['String'];
};


export type QueryChannelBrokerNextBestActionsArgs = {
  placementId: Scalars['String'];
};


export type QueryCheckWritingArgs = {
  accountId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
};


export type QueryCostBasisByAccountArgs = {
  accountId: Scalars['String'];
};


export type QueryDirectDepositStatusArgs = {
  accountId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
};


export type QueryDivCapGainsArgs = {
  accountIds?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryHoldingOptionsArgs = {
  accountId: Scalars['String'];
  positionId: Scalars['String'];
};


export type QuerySecuritiesArgs = {
  ids: Scalars['String'];
  maxNewsItemCount?: Maybe<Scalars['Int']>;
  type: SecurityIdType;
};


export type QueryTransactionHistoryArgs = {
  accountId?: Maybe<Scalars['String']>;
  maxTransactionCount?: Maybe<Scalars['Int']>;
  positionId?: Maybe<Scalars['String']>;
};

/** Registered role features of an account. */
export type RegisteredRoleFeatures = {
  __typename?: 'RegisteredRoleFeatures';
  /** Is client a Personal Trust Beneficiary for this account. */
  isPersonalTrustBeneficiary: Scalars['Boolean'];
  /** Is client a Personal Trust Co-trustee for this account. */
  isPersonalTrustCoTrustee: Scalars['Boolean'];
};

/** A client's representative. */
export type RepresentativeContact = {
  __typename?: 'RepresentativeContact';
  clientSupportPhoneNumber: Scalars['String'];
  clientSupportWorkingSchedule: Array<Schedule>;
  directPhoneNumber: Scalars['String'];
  /** The name of the representative. */
  name: Scalars['String'];
  /** The type of representative. Known values: "RMA_ADVISOR" (aka Advisor), "FLAGSHIP_REP" (aka Relationship Manager). */
  representativeType: Scalars['String'];
  /** The title of the representative. */
  title: Scalars['String'];
  /** Partial path to photo of representative. */
  webImagePath: Scalars['String'];
  workingSchedule: Array<Schedule>;
};

export type Returns = {
  __typename?: 'Returns';
  asOfDate?: Maybe<Scalars['String']>;
  fiveYear?: Maybe<Scalars['Float']>;
  oneYear?: Maybe<Scalars['Float']>;
  tenYear?: Maybe<Scalars['Float']>;
  threeYear?: Maybe<Scalars['Float']>;
  ytdAsOfDate?: Maybe<Scalars['String']>;
  ytdValue?: Maybe<Scalars['Float']>;
};

export type Schedule = {
  __typename?: 'Schedule';
  /** Days of a scheduled time. E.G. "Monday to Friday". */
  days: Scalars['String'];
  /** Hours of a scheduled time. E.G. "8 a.m. to 8 p.m., Eastern time". */
  hours: Scalars['String'];
};

export type SecYieldNote = {
  __typename?: 'SecYieldNote';
  code?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Security = {
  __typename?: 'Security';
  annualInvestmentLimit?: Maybe<Scalars['Float']>;
  closed?: Maybe<Scalars['Boolean']>;
  closedToNewInvestors?: Maybe<Scalars['Boolean']>;
  cusip?: Maybe<Scalars['String']>;
  /** Fixed-income-specific security details. */
  fixedIncomeDetails?: Maybe<FixedIncomeDetails>;
  fundId?: Maybe<Scalars['String']>;
  inceptionDate?: Maybe<Scalars['String']>;
  isVanguardFund?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** News about the security. */
  newsItems: Array<SecurityNewsItem>;
  prices?: Maybe<SecurityPrices>;
  /** @deprecated Use securityPercentageReturns which provides more information. */
  returns?: Maybe<SecurityReturns>;
  /** Fees by fee type. */
  securityFees: Array<SecurityFees>;
  securityPercentageReturns?: Maybe<SecurityPercentageReturns>;
  securityStyle?: Maybe<SecurityStyle>;
  ticker?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** The 9 box asset style id and values for securities. */
export type SecurityAssetStyle = {
  __typename?: 'SecurityAssetStyle';
  assetStyle: SecurityAssetStyleType;
  /** 9 box visual representations of investment characteristics. Example: 2 */
  nineBoxId?: Maybe<Scalars['String']>;
  /** Different style values. Example: ["Large market cap", "Blend of growth & value stocks"] */
  styleValues: Array<Scalars['String']>;
};

export enum SecurityAssetStyleType {
  Bond = 'BOND',
  Stock = 'STOCK'
}

export enum SecurityFeeType {
  LoadFee = 'LOAD_FEE',
  PurchaseFee = 'PURCHASE_FEE',
  RedemptionFee = 'REDEMPTION_FEE',
  TotalExpenseRatio = 'TOTAL_EXPENSE_RATIO',
  TransactionFee = 'TRANSACTION_FEE',
  TwelveB1Fee = 'TWELVE_B1_FEE'
}

export type SecurityFees = {
  __typename?: 'SecurityFees';
  /** Indicates applicability of fee. Example: None. */
  description?: Maybe<Scalars['String']>;
  feeType: SecurityFeeType;
};

export enum SecurityIdType {
  Cusip = 'CUSIP',
  OptionTicker = 'OPTION_TICKER',
  Ticker = 'TICKER',
  VgFundId = 'VG_FUND_ID'
}

export type SecurityNewsItem = {
  __typename?: 'SecurityNewsItem';
  headLine?: Maybe<Scalars['String']>;
  newsId?: Maybe<Scalars['String']>;
  publishedDateTime?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  teaser?: Maybe<Scalars['String']>;
  tickerId?: Maybe<Scalars['String']>;
};

export type SecurityPercentageReturns = {
  __typename?: 'SecurityPercentageReturns';
  /** Benchmark tooltip source data for percentage returns. */
  benchmarkShortName?: Maybe<Scalars['String']>;
  /** Returns by duration type. These may not be the most recent but they are the most comprehensive. */
  percentageReturns: Array<PercentageReturns>;
  /** As of date for percentage returns. */
  percentageReturnsAsOfDate?: Maybe<Scalars['String']>;
  /** Previous business day Returns by duration type. These may not be the most comprehensive, but they are the most recent. */
  prevBusinessDayPercentageReturns: Array<PrevBusinessDayPercentageReturns>;
  /** As of date for previous-business-day percentage returns. */
  prevBusinessDayReturnsAsOfDate?: Maybe<Scalars['String']>;
};

export type SecurityPrices = {
  __typename?: 'SecurityPrices';
  asOfDate?: Maybe<Scalars['String']>;
  askPrice?: Maybe<Scalars['String']>;
  askSize?: Maybe<Scalars['String']>;
  bidPrice?: Maybe<Scalars['String']>;
  bidSize?: Maybe<Scalars['String']>;
  changeAmount?: Maybe<Scalars['String']>;
  changePercent?: Maybe<Scalars['String']>;
  cutoffTimes?: Maybe<CutoffTimes>;
  expenseRatio?: Maybe<Scalars['String']>;
  fiftyTwoWeekHighDate?: Maybe<Scalars['String']>;
  fiftyTwoWeekHighPrice?: Maybe<Scalars['String']>;
  fiftyTwoWeekLowDate?: Maybe<Scalars['String']>;
  fiftyTwoWeekLowPrice?: Maybe<Scalars['String']>;
  nav?: Maybe<Scalars['String']>;
  option?: Maybe<Option>;
  /** Deprecated, data included in code field included in secYieldNote fields */
  secYieldCode?: Maybe<Scalars['String']>;
  secYieldDate?: Maybe<Scalars['String']>;
  secYieldNote: Array<SecYieldNote>;
  /** Deprecated, data included in period field included in secYieldNote fields */
  secYieldPeriod?: Maybe<Scalars['String']>;
  /** Deprecated, data included in text field included in secYieldNote fields */
  secYieldText?: Maybe<Scalars['String']>;
  secYieldValue?: Maybe<Scalars['String']>;
  todaysVolume?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Deprecated in favor of SecurityPercentageReturns which provides more information. */
export type SecurityReturns = {
  __typename?: 'SecurityReturns';
  asOfDate?: Maybe<Scalars['String']>;
  fiveYear?: Maybe<Scalars['Float']>;
  oneYear?: Maybe<Scalars['Float']>;
  tenYear?: Maybe<Scalars['Float']>;
  threeYear?: Maybe<Scalars['Float']>;
  yearToDate?: Maybe<Scalars['Float']>;
  yearToDateAsOfDate?: Maybe<Scalars['String']>;
};

/** Returns style associated with a security such as mutual fund, money market, ETF. */
export type SecurityStyle = {
  __typename?: 'SecurityStyle';
  /** General categories of investments. The three major asset classes are cash investments, bonds, and stocks. Example: Balanced */
  assetClass?: Maybe<Scalars['String']>;
  /** Bond investment characteristics. */
  bondAssetStyle?: Maybe<SecurityAssetStyle>;
  /** The type of investment within the asset class. Example: Moderate Allocation */
  category?: Maybe<Scalars['String']>;
  /** Stock investment characteristics. */
  stockAssetStyle?: Maybe<SecurityAssetStyle>;
};

export type SettlementFund = {
  __typename?: 'SettlementFund';
  accountId?: Maybe<Scalars['String']>;
  adjustedFundsAvailable90day?: Maybe<Scalars['Float']>;
  adjustedFundsAvailableToWithdraw90day?: Maybe<Scalars['Float']>;
  availableBalance?: Maybe<Scalars['Float']>;
  brokerageAccountNumber?: Maybe<Scalars['String']>;
  buyingPowerValue?: Maybe<Scalars['Float']>;
  cashTradeDateBalance?: Maybe<Scalars['Float']>;
  fedCall?: Maybe<Scalars['Float']>;
  fundsAvailableToTrade?: Maybe<Scalars['Float']>;
  fundsAvailableToWithdraw?: Maybe<Scalars['Float']>;
  hasRestriction?: Maybe<Scalars['Boolean']>;
  houseCall?: Maybe<Scalars['Float']>;
  isMargin?: Maybe<Scalars['Boolean']>;
  longMarketValue?: Maybe<Scalars['Float']>;
  marginTradeDateBalance?: Maybe<Scalars['Float']>;
  openBuyMarketableOrderValue?: Maybe<Scalars['Float']>;
  openLimitOrderValue?: Maybe<Scalars['Float']>;
  pendingActivity?: Maybe<Array<Activity>>;
  settlementFundBalance?: Maybe<Scalars['Float']>;
  shortMarketValue?: Maybe<Scalars['Float']>;
  shortTradeDateBalance?: Maybe<Scalars['Float']>;
  totalAccountValue?: Maybe<Scalars['Float']>;
  totalCreditsAndDebits?: Maybe<Scalars['Float']>;
  totalTradeDateBalance?: Maybe<Scalars['Float']>;
  tradeActivity?: Maybe<Array<Activity>>;
  uncollectedAmount?: Maybe<Scalars['Float']>;
};

export type Totals = {
  __typename?: 'Totals';
  managed?: Maybe<Scalars['Float']>;
  outsideInvestments?: Maybe<Scalars['Float']>;
  privateEquityTotalBalance?: Maybe<Scalars['Float']>;
  selfManaged?: Maybe<Scalars['Float']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalBalanceAsOfDate?: Maybe<Scalars['String']>;
  vanguardAssets?: Maybe<Scalars['Float']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  netAmount?: Maybe<Scalars['Float']>;
  portId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  shareQuantity?: Maybe<Scalars['Float']>;
  tradeDate?: Maybe<Scalars['String']>;
  transactionDescription?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};

export type TransactionHistory = {
  __typename?: 'TransactionHistory';
  accountId?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  transactions: Array<Transaction>;
};

export type AccountsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsDataQuery = (
  { __typename?: 'Query' }
  & { accountsInfo: (
    { __typename?: 'AccountsInfo' }
    & { privateEquityInfo?: Maybe<(
      { __typename?: 'PrivateEquityInfo' }
      & { privateEquityAccounts: Array<(
        { __typename?: 'PrivateEquity' }
        & Pick<PrivateEquity, 'accountName' | 'totalBalance'>
        & { associatedAccount?: Maybe<(
          { __typename?: 'AssociatedAccount' }
          & Pick<AssociatedAccount, 'vendorAccountNumber' | 'productType'>
        )> }
      )> }
    )>, accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'balance' | 'grouping' | 'isBrokerage' | 'brokerageAccountNumber' | 'isParticipant' | 'isCashManagement' | 'productType'>
      & { accountFlags?: Maybe<(
        { __typename?: 'AccountFlags' }
        & Pick<AccountFlags, 'accountId' | 'mergeExpandPending' | 'transferOfAssetsPending'>
      )> }
    )>, accountErrors?: Maybe<(
      { __typename?: 'AccountErrors' }
      & Pick<AccountErrors, 'hasInstitutionalError'>
    )>, totals?: Maybe<(
      { __typename?: 'Totals' }
      & Pick<Totals, 'managed' | 'selfManaged' | 'vanguardAssets' | 'outsideInvestments' | 'totalBalance' | 'totalBalanceAsOfDate' | 'privateEquityTotalBalance'>
    )> }
  ) }
);

export type ClientAlertsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientAlertsDataQuery = (
  { __typename?: 'Query' }
  & { clientAlerts: (
    { __typename?: 'ClientAlerts' }
    & Pick<ClientAlerts, 'returnByPostOffice' | 'recentlyAddedBank'>
  ) }
);

export type ClientInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientInfoQuery = (
  { __typename?: 'Query' }
  & { client: (
    { __typename?: 'Client' }
    & Pick<Client, 'institutional'>
  ), customViewDetails: (
    { __typename?: 'CustomViewDetails' }
    & Pick<CustomViewDetails, 'customView'>
  ) }
);

export type ContactUsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactUsDataQuery = (
  { __typename?: 'Query' }
  & { contactUs?: Maybe<(
    { __typename?: 'ContactUs' }
    & Pick<ContactUs, 'rmaClient' | 'flagshipClient' | 'supportPhoneNumber' | 'advisorAppointmentUrl' | 'relationshipManagerAppointmentUrl'>
    & { supportSchedule: Array<(
      { __typename?: 'Schedule' }
      & Pick<Schedule, 'days' | 'hours'>
    )>, representativeContacts: Array<(
      { __typename?: 'RepresentativeContact' }
      & Pick<RepresentativeContact, 'name' | 'title' | 'representativeType' | 'webImagePath' | 'directPhoneNumber' | 'clientSupportPhoneNumber'>
      & { workingSchedule: Array<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'days' | 'hours'>
      )>, clientSupportWorkingSchedule: Array<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'days' | 'hours'>
      )> }
    )> }
  )> }
);

export type ContingencyFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContingencyFlagsQuery = (
  { __typename?: 'Query' }
  & { contingencyFlags: (
    { __typename?: 'ContingencyFlags' }
    & Pick<ContingencyFlags, 'isContingency' | 'isIpl' | 'isMutualFundsAtp' | 'isEtfsAtp'>
  ) }
);

export const AccountsDataDocument = gql`
    query accountsData {
  accountsInfo {
    privateEquityInfo {
      privateEquityAccounts {
        accountName
        totalBalance
        associatedAccount {
          vendorAccountNumber
          productType
        }
      }
    }
    accounts {
      id
      name
      balance
      grouping
      isBrokerage
      brokerageAccountNumber
      accountFlags {
        accountId
        mergeExpandPending
        transferOfAssetsPending
      }
      isParticipant
      isCashManagement
      productType
    }
    accountErrors {
      hasInstitutionalError
    }
    totals {
      managed
      selfManaged
      vanguardAssets
      outsideInvestments
      totalBalance
      totalBalanceAsOfDate
      privateEquityTotalBalance
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountsDataGQL extends Apollo.Query<AccountsDataQuery, AccountsDataQueryVariables> {
    document = AccountsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClientAlertsDataDocument = gql`
    query clientAlertsData {
  clientAlerts {
    returnByPostOffice
    recentlyAddedBank
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClientAlertsDataGQL extends Apollo.Query<ClientAlertsDataQuery, ClientAlertsDataQueryVariables> {
    document = ClientAlertsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClientInfoDocument = gql`
    query clientInfo {
  client {
    institutional
  }
  customViewDetails {
    customView
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClientInfoGQL extends Apollo.Query<ClientInfoQuery, ClientInfoQueryVariables> {
    document = ClientInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactUsDataDocument = gql`
    query contactUsData {
  contactUs {
    rmaClient
    flagshipClient
    supportPhoneNumber
    supportSchedule {
      days
      hours
    }
    advisorAppointmentUrl
    relationshipManagerAppointmentUrl
    representativeContacts {
      name
      title
      representativeType
      webImagePath
      directPhoneNumber
      workingSchedule {
        days
        hours
      }
      clientSupportPhoneNumber
      clientSupportWorkingSchedule {
        days
        hours
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactUsDataGQL extends Apollo.Query<ContactUsDataQuery, ContactUsDataQueryVariables> {
    document = ContactUsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContingencyFlagsDocument = gql`
    query contingencyFlags {
  contingencyFlags {
    isContingency
    isIpl
    isMutualFundsAtp
    isEtfsAtp
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContingencyFlagsGQL extends Apollo.Query<ContingencyFlagsQuery, ContingencyFlagsQueryVariables> {
    document = ContingencyFlagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }